import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

export const StyledBetslipValueContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  margin: '$3 0',
  width: '100%',
  gap: '$4',
  '@xs_sm': {
    fontSize: '$xxs',
    lineHeight: '$20',
    gap: '$1',
    backgroundColor: '$grayDark',
    p: '$2 $3',
    borderRadius: '$8',
    width: 'unset',
  },
});

export const StyledBetConfirmationBtnText = styled(Text, {
  fontSize: '12px',
  lineHeight: '20px',
  '@sm': {
    fontSize: '16px',
    lineHeight: '28px',
  },
});

export const StyledBetslipConfirmationWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$3',
  padding: '$4',
  borderRadius: '$8',
  backgroundColor: '$grayDark',
  zIndex: 3,
  '@xs_sm': {
    width: '100%',
  },
});

export const StyledConfirmationCloseButton = styled(Box, {
  position: 'absolute',
  top: '$6',
  right: '$6',
  color: '$grayMedium',
});

export const StyledConfirmationBackgroundBlur = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'fixed',
  borderRadius: '$10',
  background: 'rgba(10, 15, 20, 0.70)',
  backdropFilter: 'blur(4px)',
  zIndex: 2,
});

export const StyledSubmitButton = styled(Button, {
  padding: '$2',
  width: '100%',
  '@xs_sm': {
    borderRadius: '$4',
  },
  variants: {
    isQuickBet: {
      true: {
        padding: '$1',
      },
    },
    isBetslipChanged: {
      true: {
        backgroundColor: '$yellowDark !important',
        color: '$yellow !important',
      },
    },
  },
});

export const StyledSubmitButtonText = styled(Text, {
  fontSize: '16px',
  lineHeight: '24px',
  color: '$buttonTextColor',
  fontWeight: '$bold !important',
  textAlign: 'center',
  variants: {
    isQuickBet: {
      true: {
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
    isBetslipChanged: {
      true: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '$yellow',
      },
    },
  },
});

export const StyledQuickBetButton = styled(Button, {
  p: '$2',
  width: '100%',
  '@xs_sm': {
    lineHeight: '$20',
    fontSize: '$12',
    p: '$1',
    borderRadius: '$4',
  },
});

export const StyledQuickBetButtonText = styled(Text, {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '$bold !important',
  textAlign: 'center',
  '@xs_sm': {
    fontSize: '$12',
    lineHeight: '$20',
  },
  isBetslipChanged: {
    true: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
});

import React from 'react';

import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { BetslipConfirmationPopover } from '~components/molecules/Betslip/components/BetslipFooter/BetslipConfirmationPopover';
import {
  StyledQuickBetButton,
  StyledQuickBetButtonText,
  StyledSubmitButton,
  StyledSubmitButtonText,
} from '~components/molecules/Betslip/components/BetslipFooter/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { isEmptyObject } from '~utils/objectHelpers';

interface BetslipSubmitButtonProps {
  quickBet?: boolean;
  onClick: () => Promise<void>;
  onDeleteClick: () => void;
  isDisabled: boolean;
  isUserLoggedIn: boolean;
  isBetslipChanged: string[];
  isConfirmOpen: boolean;
  setIsConfirmOpen: (isOpen: boolean) => void;
  localizationPath: string;
}

export const BetslipSubmitButton = ({
  quickBet = false,
  onClick,
  onDeleteClick,
  isDisabled,
  isUserLoggedIn,
  isBetslipChanged,
  isConfirmOpen,
  setIsConfirmOpen,
  localizationPath,
}: BetslipSubmitButtonProps) => {
  const { localized } = useTranslation();
  const { isProcessing, stakePossibleWinLoadingMap } = useAppSelector(
    (state) => state.betslip,
  );

  const isLoading = isProcessing || !isEmptyObject(stakePossibleWinLoadingMap);
  const isBetslipDataChanged = !!isBetslipChanged?.length && isUserLoggedIn;
  const handleBetButtonClick = () => {
    // We don't show popover for quick bets and when user not logged in
    if (!isUserLoggedIn || quickBet) {
      return onClick();
    }

    setIsConfirmOpen(true);
  };

  return (
    <Popover isOpen={isConfirmOpen}>
      <PopoverContent side="top" tone="secondary" css={{ m: 0, p: 0 }}>
        <BetslipConfirmationPopover
          setIsOpen={setIsConfirmOpen}
          onConfirm={onClick}
        />
      </PopoverContent>
      <PopoverTrigger asChild>
        <Box flexRow gap={2}>
          {quickBet && (
            <StyledQuickBetButton
              disabled={isLoading}
              variant="secondary"
              onClick={onDeleteClick}
            >
              <StyledQuickBetButtonText>
                {localized('buttons.delete')}
              </StyledQuickBetButtonText>
            </StyledQuickBetButton>
          )}
          <StyledSubmitButton
            isQuickBet={quickBet}
            isBetslipChanged={isBetslipDataChanged}
            disabled={(isUserLoggedIn && isDisabled) || isLoading}
            onClick={handleBetButtonClick}
          >
            <StyledSubmitButtonText
              isQuickBet={quickBet}
              isBetslipChanged={isBetslipDataChanged}
            >
              {localized(localizationPath)}
            </StyledSubmitButtonText>
          </StyledSubmitButton>
        </Box>
      </PopoverTrigger>
    </Popover>
  );
};
